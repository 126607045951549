<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="订单编号：">
        <el-input size="small" placeholder="请输入订单编号" v-model="formData.order_no"></el-input>
      </el-form-item>
      <el-form-item label="收货人姓名：">
        <el-input size="small" placeholder="请输入收货人姓名" v-model="formData.receiver_name"></el-input>
      </el-form-item>
      <el-form-item label="收货人手机号：">
        <el-input size="small" placeholder="请输入收货人手机号" v-model="formData.receiver_phone"></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker v-model="formData.time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="商品名称：">
        <el-input size="small" placeholder="请输入商品名称/编号" v-model="formData.goods_name"></el-input>
      </el-form-item>
      <el-form-item label="店铺名称：">
        <el-input size="small" placeholder="请输入店铺名称" v-model="formData.shop_name"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="45px">
        <el-button size="small" type="primary" @click="getCompanyOrder(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="card" v-model="formData.state_type" @tab-click="() => getCompanyOrder(1)">
      <el-tab-pane v-for="item in order_source" :key="item.key" :label="item.name + `(${item.num})`" :name="item.key"></el-tab-pane>
    </el-tabs>
    <!-- 商品列表 -->
    <table class="order_list">
      <thead>
        <tr>
          <th>商品信息</th>
          <th>单价/数量</th>
          <th>总价</th>
          <th>收货人</th>
          <th>处理时间</th>
          <th>订单状态</th>
          <th>操作</th>
        </tr>
        <tr style="height: 10px"></tr>
      </thead>
      <tbody v-for="item in formData.dataList" :key="item.id">
        <tr>
          <td colspan="7" class="order_data_top">
            <div class="td_box">
              <div class="order_info">
                <div class="order_no">订单编号:{{ item.pay_sn }}</div>
                <div class="store_name">
                  <el-tag type="success">
                    {{ item.shop_info.shop_name }}
                  </el-tag>
                </div>
              </div>
              <div>
                <el-button v-show="item.state != 20 && item.state != 10 && item.state != 0" type="text" size="small" @click="logisticsQuery(item.id)">物流信息</el-button>
                <el-button class="order_detail" type="text" @click="orderInfo(item.id)">订单详情</el-button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="goods_info">
            <div v-for="goods in item.extend_order_goods" :key="goods.id">
              <el-image :src="goods.goods_img" class="goods_img"></el-image>
              <div class="goods_name">
                <div class="name">{{ goods.goods_title }}</div>
                <div v-if="goods.goods_specs" style="margin-top: 5px">
                  规格:
                  <span v-for="(spec, s_i) in goods.goods_specs" :key="s_i">{{ !s_i ? spec.attr_key : ',' + spec.attr_key }}</span>
                </div>
              </div>
              <el-tag type="warning">
                {{ goods.goods_type == 1 ? '自营' : goods.goods_type == 2 ? 'CPS' : '视频号' }}
              </el-tag>
            </div>
          </td>
          <td class="p_nums">
            <div style="height: 70px" v-for="goods in item.extend_order_goods" :key="goods.id">
              <div>￥{{ goods.goods_pay_price }}</div>
              <div>x{{ goods.goods_num }}</div>
            </div>
          </td>
          <td class="t_price">
            <div>￥{{ item.amount }}</div>
          </td>
          <td class="p_nums">
            <div>{{ item.user_name }}</div>
            <div>{{ item.user_phone }}</div>
          </td>
          <td class="t_price">
            <div>{{ getDateformat(item.update_time) }}</div>
          </td>
          <td class="t_price">
            <div :style="{ color: item.state == 20 ? '#ff7d7c' : item.state == 30 ? '#fdc37b' : '#3571ff' }">
              {{ item.state == 0 ? '已关闭' : item.state == 10 ? '待支付' : item.state == 20 ? '待发货' : item.state == 30 ? '待收货' : '已完成' }}
            </div>
          </td>
          <td>
            <el-button type="primary" size="small" v-if="item.state == 20" @click="orderSend(item)">发货</el-button>
            <el-button v-if="item.state == 10" type="text" size="small" @click.stop="cancelOrder(item)">取消订单</el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <Paging :total="formData.total" :page="formData.page" :pageNum="formData.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="发货" :visible.sync="showOrderSend" width="600px">
      <el-form label-width="90px" v-if="currentSelectOrderInfo.id">
        <el-form-item label="收货人：">{{ currentSelectOrderInfo.extend_order_extend.reciver_infos.name }}</el-form-item>
        <el-form-item label="收货地址：">
          {{ currentSelectOrderInfo.extend_order_extend.reciver_infos.combine_detail }}{{ currentSelectOrderInfo.extend_order_extend.reciver_infos.address }}
        </el-form-item>
        <el-form-item label="快递公司：">
          <el-select v-model="express_id" filterable placeholder="请搜索">
            <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号：">
          <el-input v-model="tracking_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showOrderSend = !1">取 消</el-button>
        <el-button type="primary" @click="confirmSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="物流信息" :visible.sync="showGistics" width="40%">
      <p style="font-size: 16px; font-weight: 500; margin-bottom: 15px">{{ logisticsInfo.company_name }}：{{ logisticsInfo.nu }}</p>
      <el-timeline :reverse="reverse">
        <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
          {{ item.context }}
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGistics = !1">取 消</el-button>
        <el-button type="primary" @click="showGistics = !0">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="取消订单" :visible.sync="showCancelOrder" width="35%">
      <el-form v-if="currentSelectOrderInfo.id" label-width="85px">
        <el-form-item label="取消原因：">
          <el-select v-model="cancel_reason_id" filterable placeholder="请选择取消原因">
            <el-option v-for="item in cancelList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取消说明：">
          <el-input v-model="state_remark" type="textarea" :rows="8" placeholder="请输入取消说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCancelOrder = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmCancelOrder">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
import { mapState } from 'vuex';
/**
 * 搜索订单状态无效
 * 页面功能未完善
 */
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      showOrderSend: !1,
      tracking_no: '',
      currentSelectOrderInfo: {},
      express_id: '',
      showGistics: !1,
      logisticsInfo: {},
      showCancelOrder: !1,
      state_remark: '',
      cancel_reason_id:'',
      formData: {
        page: 1,
        rows: 10,
        state_type: 'all',
        time: [],
        order_no: '',
        receiver_name: '',
        goods_name: '',
        shop_name: '',
        total: 0,
        dataList: [],
      },
      order_source: [
        { name: '全部订单', key: 'all', num: 0 },
        { name: '待支付', key: 'state_new', num: 0 },
        { name: '待发货', key: 'state_pay', num: 0 },
        { name: '待收货', key: 'state_send', num: 0 },
        { name: '已完成', key: 'state_success', num: 0 },
        { name: '已关闭', key: 'state_close', num: 0 },
      ],
    };
  },
  computed: {
    ...mapState({
      logisticsList: state => state.order.logisticsList, //物流公司列表
      cancelList: state => state.order.cancelList,
    }),
  },
  watch: {
    showOrderSend(val) {
      if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
    },
    showCancelOrder(val) {
      if (val && !this.cancelList.length) this.$store.dispatch('order/getCancelList', { type: 4 });
    },
  },
  created() {
    this.getCompanyOrder();
  },
  methods: {
    clearSearch() {
      this.formData = {
        page: 1,
        rows: 10,
        state_type: 'all',
        time: [],
        order_no: '',
        receiver_name: '',
        goods_name: '',
        shop_name: '',
        total: 0,
        dataList: [],
      };
      this.getCompanyOrder();
    },
    updateData(val, status) {
      if (status == 0) {
        this.formData.rows = val;
      } else {
        this.formData.page = val;
      }
      this.getCompanyOrder();
    },
    orderInfo(id) {
      this.$router.push({
        path: '/extension/gatherTweeters/enterpriseOrderInfo',
        query: {
          id: id,
        },
      });
    },
    //取消订单
    cancelOrder(row) {
      this.currentSelectOrderInfo = row;
      this.state_remark = '';
      this.showCancelOrder = !0;
    },
    comfirmCancelOrder() {
      if (!this.cancel_reason_id)
        return this.$message({
          message: '请选择取消原因',
          type: 'warning',
        });
      if (!this.state_remark)
        return this.$message({
          message: '请填写取消说明',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.serviceProvider.companyOrderCancel, {
          id: this.currentSelectOrderInfo.id,
          state_remark: this.state_remark,
          cancel_reason_id: this.cancel_reason_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showCancelOrder = !1;
            this.$message({
              message: '订单已取消',
              type: 'success',
            });
            this.getCompanyOrder();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //查看物流信息
    logisticsQuery(id) {
      this.$axios.post(this.$api.serviceProvider.logisticsQuery, { id: id }).then(res => {
        if (res.code == 0) {
          this.showGistics = !0;
          this.logisticsInfo = res.result.info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //发货
    orderSend(row) {
      let orderInfo = row.extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
      }
      this.currentSelectOrderInfo = row;
      this.tracking_no = '';
      this.showOrderSend = !0;
    },
    confirmSend() {
      if (!this.tracking_no) return this.$message.warning('请填写快递单号');
      this.$axios
        .post(this.$api.serviceProvider.companyOrderSend, {
          id: this.currentSelectOrderInfo.id,
          need_express: 1,
          express_id: this.express_id,
          tracking_no: this.tracking_no,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('发货成功');
            this.getCompanyOrder();
            this.showOrderSend = !1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getCompanyOrder(style) {
      if (style) this.formData.page = 1;
      let obj = {
        page: this.formData.page,
        rows: this.formData.rows,
        state_type: this.formData.state_type,
      };
      if (this.formData.time?.length) {
        obj.start_time = this.formData.time[0].getTime() / 1000;
        obj.end_time = this.formData.time[1].getTime() / 1000;
        if (obj.start_time == obj.end_time) obj.end_time = obj.end_time + 86400;
      }
      if (this.formData.order_no) obj.order_no = this.formData.order_no;
      if (this.formData.receiver_name) obj.receiver_name = this.formData.receiver_name;
      if (this.formData.goods_name) obj.goods_name = this.formData.goods_name;
      if (this.formData.shop_name) obj.shop_name = this.formData.shop_name;
      this.$axios.post(this.$api.serviceProvider.companyOrder, obj).then(res => {
        if (res.code == 0) {
          this.formData.total = res.result.total;
          this.formData.dataList = res.result.list;
          this.getOrderNum();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getOrderNum() {
      this.$axios.post(this.$api.serviceProvider.companyOrderStateNum).then(res => {
        if (res.code == 0) {
          // 获取订单数
          let order_sum = 0;
          for (const key in res.result) {
            let target = this.order_source.find(item => item.key == key);
            order_sum += res.result[key];
            if (target) target.num = res.result[key];
          }
          this.order_source[0].num = order_sum;
        } else {
          this.$message.success(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;

  .order_list {
    width: 100%;
    font-size: 14px;

    thead {
      background-color: #f8f9fa;

      th:first-child {
        width: 400px;
      }

      th {
        padding: 15px 0;
        text-align: center;
      }
    }

    .td_box {
      display: flex;
    }

    .order_data_top {
      padding: 5px 20px;
      border-bottom: 1px solid #ebeef5;
      background: #f8f9fa;

      .td_box {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .order_info {
          display: flex;
          align-items: center;

          .order_no {
            margin-right: 10px;
          }
        }
      }
    }

    .goods_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 20px;

      & > div {
        display: flex;
        align-items: center;
        height: 70px;
      }

      .goods_name {
        width: 220px;

        .name {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

      .goods_img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 5px;
      }
    }

    .p_nums {
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .t_price {
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>